import {
  GET_ORDER_DT,
  GET_ORDER_DT_OK,
  GET_ORDER_DT_ERR,
  DO_ORDER_DT_CLEANUP,

  APPLY_ORDER_DT_FILTERS,
  PATCH_ORDER_DT_FILTERS,
  CLEAR_ORDER_DT_FILTERS,
  UNSET_ORDER_DT_FILTERS,
  DO_ORDER_DT_FILTERS_CLEANUP,

  GET_IN_PROGRESS_ORDER_LIST,
  GET_ORDER_LIST_OK,
  GET_ORDER_LIST_ERR,
  DO_ORDER_LIST_CLEANUP,

  GET_ORDER,
  GET_ORDER_WITH_CUSTOMER_SIGNERS,
  GET_ORDER_WITH_CUSTOMER_SIGNERS_AND_NOTARIES,
  GET_ORDER_WITH_VEHICLES,
  GET_FULL_ORDER,
  GET_ORDER_OK,
  GET_ORDER_WITH_CUSTOMER_SIGNERS_OK,
  GET_ORDER_WITH_CUSTOMER_SIGNERS_AND_NOTARIES_OK,
  GET_ORDER_WITH_VEHICLES_OK,
  GET_FULL_ORDER_OK,
  GET_ORDER_ERR,
  PATCH_ORDER,
  SEAL_ORDER,
  SEAL_ORDER_OK,
  SEAL_ORDER_ERR,
  RESEND_CUSTOMER_NOTIFICATIONS,
  RESEND_CUSTOMER_NOTIFICATIONS_OK,
  RESEND_CUSTOMER_NOTIFICATIONS_ERR,
  START_CUSTOMER_MEETING,
  START_CUSTOMER_MEETING_OK,
  START_CUSTOMER_MEETING_ERR,
  START_SCHEDULER_MEETING,
  START_SCHEDULER_MEETING_OK,
  START_SCHEDULER_MEETING_ERR,
  DO_ORDER_SINGLE_CLEANUP,
  RESET_ORDER_ACTION_FLAG,

  CREATE_ORDER,
  UPDATE_ORDER_SERVICES,
  UPDATE_ORDER_CUSTOMER,
  UPDATE_ORDER_VEHICLE,
  UPDATE_ORDER_CONTRACT,
  UPDATE_ORDER_DOCS,
  SUBMIT_ORDER,
  SAVE_ORDER_OK,
  SAVE_ORDER_ERR,
  DO_ORDER_FORM_CLEANUP,

  GET_ORDER_VID,
  GET_ORDER_VID_OK,
  GET_ORDER_VID_ERR,
  DO_ORDER_VID_CLEANUP,

  GET_ORDER_INK_SIGN_DOCS,
  GET_ORDER_INK_SIGN_DOCS_OK,
  GET_ORDER_INK_SIGN_DOCS_ERR,
  DO_ORDER_INK_SIGN_DOCS_CLEANUP,
  GET_ORDER_E_SIGN_DOCS,
  GET_ORDER_E_SIGN_DOCS_OK,
  GET_ORDER_E_SIGN_DOCS_ERR,
  DO_ORDER_E_SIGN_DOCS_CLEANUP,

  GET_ORDER_ACTIVITY,
  GET_ORDER_ACTIVITY_OK,
  GET_ORDER_ACTIVITY_ERR,
  DO_ORDER_ACTIVITY_CLEANUP,

  GET_ORDER_MESSAGES,
  GET_ORDER_MESSAGES_OK,
  GET_ORDER_MESSAGES_ERR,
  DO_ORDER_MESSAGES_CLEANUP,
  GET_LAST_ORDER_MESSAGE,
  GET_LAST_ORDER_MESSAGE_OK,
  GET_LAST_ORDER_MESSAGE_ERR,
  GET_CHAT_MEMBERS,
  GET_CHAT_MEMBERS_OK,
  GET_CHAT_MEMBERS_ERR,
  GET_ORDER_SUPPORTING_DOCS,
  GET_ORDER_SUPPORTING_DOCS_OK,
  GET_ORDER_SUPPORTING_DOCS_ERR,
  DO_ORDER_SUPPORTING_DOCS_CLEANUP,
  GET_ORDER_PREVIEW_DOCS,
  GET_ORDER_PREVIEW_DOCS_OK,
  GET_ORDER_PREVIEW_DOCS_ERR,
  DO_ORDER_PREVIEW_DOCS_CLEANUP,
  RESET_CHANNEL_UNREAD_MESSAGES,
} from './actionTypes';

/********** DATATABLE **********/

export const getOrderDt = (params, silentLoad = false) => ({
  type: GET_ORDER_DT,
  payload: { params, silentLoad },
});

export const getOrderDtOk = response => ({
  type: GET_ORDER_DT_OK,
  payload: { response },
});

export const getOrderDtErr = error => ({
  type: GET_ORDER_DT_ERR,
  payload: { error },
});

export const doOrderDtCleanup = () => ({
  type: DO_ORDER_DT_CLEANUP,
});

/********** DATATABLE FILTERS **********/

export const applyOrderDtFilters = filters => ({
  type: APPLY_ORDER_DT_FILTERS,
  payload: { filters },
});

export const patchOrderDtFilters = filters => ({
  type: PATCH_ORDER_DT_FILTERS,
  payload: { filters },
});

export const clearOrderDtFilters = () => ({
  type: CLEAR_ORDER_DT_FILTERS,
});

export const unsetOrderDtFilters = () => ({
  type: UNSET_ORDER_DT_FILTERS,
});

export const doOrderDtFiltersCleanup = () => ({
  type: DO_ORDER_DT_FILTERS_CLEANUP,
});

/********** LIST **********/

export const getInProgressOrderList = () => ({
  type: GET_IN_PROGRESS_ORDER_LIST,
})

export const getOrderListOk = response => ({
  type: GET_ORDER_LIST_OK,
  payload: { response },
})

export const getOrderListErr = error => ({
  type: GET_ORDER_LIST_ERR,
  payload: { error },
});

export const doOrderListCleanup = () => ({
  type: DO_ORDER_LIST_CLEANUP,
});

/********** SINGLE **********/

export const getOrder = id => ({
  type: GET_ORDER,
  payload: { id },
});

export const getOrderWithCustomerSigners = id => ({
  type: GET_ORDER_WITH_CUSTOMER_SIGNERS,
  payload: { id },
});

export const getOrderWithCustomerSignersAndNotaries = id => ({
  type: GET_ORDER_WITH_CUSTOMER_SIGNERS_AND_NOTARIES,
  payload: { id },
});

export const getOrderWithVehicles = id => ({
  type: GET_ORDER_WITH_VEHICLES,
  payload: { id },
});

export const getFullOrder = id => ({
  type: GET_FULL_ORDER,
  payload: { id },
});

export const getOrderOk = response => ({
  type: GET_ORDER_OK,
  payload: { response },
});

export const getOrderWithCustomerSignersOk = (orderResponse, signersResponse) => ({
  type: GET_ORDER_WITH_CUSTOMER_SIGNERS_OK,
  payload: { orderResponse, signersResponse },
});

export const getOrderWithCustomerSignersAndNotariesOk = (orderResponse, signersResponse, notariesResponse) => ({
  type: GET_ORDER_WITH_CUSTOMER_SIGNERS_AND_NOTARIES_OK,
  payload: { orderResponse, signersResponse, notariesResponse },
});

export const getOrderWithVehiclesOk = (orderResponse, vehiclesResponse) => ({
  type: GET_ORDER_WITH_VEHICLES_OK,
  payload: { orderResponse, vehiclesResponse },
});

export const getFullOrderOk = (orderResponse, signersResponse, vehiclesResponse, notariesResponse) => ({
  type: GET_FULL_ORDER_OK,
  payload: { orderResponse, signersResponse, vehiclesResponse, notariesResponse },
});

export const getOrderErr = error => ({
  type: GET_ORDER_ERR,
  payload: { error },
});

export const patchOrder = data => ({
  type: PATCH_ORDER,
  payload: { data },
});

export const sealOrder = (id, docIds) => ({
  type: SEAL_ORDER,
  payload: { id, docIds },
});

export const sealOrderOk = response => ({
  type: SEAL_ORDER_OK,
  payload: { response },
});

export const sealOrderErr = error => ({
  type: SEAL_ORDER_ERR,
  payload: { error },
});

export const resendCustomerNotifications = id => ({
  type: RESEND_CUSTOMER_NOTIFICATIONS,
  payload: { id },
});

export const resendCustomerNotificationsOk = response => ({
  type: RESEND_CUSTOMER_NOTIFICATIONS_OK,
  payload: { response },
});

export const resendCustomerNotificationsErr = error => ({
  type: RESEND_CUSTOMER_NOTIFICATIONS_ERR,
  payload: { error },
});

export const startCustomerMeeting = orderId => ({
  type: START_CUSTOMER_MEETING,
  payload: { orderId },
});

export const startCustomerMeetingOk = response => ({
  type: START_CUSTOMER_MEETING_OK,
  payload: { response },
});

export const startCustomerMeetingErr = error => ({
  type: START_CUSTOMER_MEETING_ERR,
  payload: { error },
});

export const startSchedulerMeeting = orderId => ({
  type: START_SCHEDULER_MEETING,
  payload: { orderId },
});

export const startSchedulerMeetingOk = response => ({
  type: START_SCHEDULER_MEETING_OK,
  payload: { response },
});

export const startSchedulerMeetingErr = error => ({
  type: START_SCHEDULER_MEETING_ERR,
  payload: { error },
});

export const doOrderSingleCleanup = () => ({
  type: DO_ORDER_SINGLE_CLEANUP,
});

export const resetOrderActionFlag = flag => ({
  type: RESET_ORDER_ACTION_FLAG,
  payload: { flag },
});

/********** FORM **********/

export const createOrder = data => ({
  type: CREATE_ORDER,
  payload: { data },
});

export const updateOrderServices = (data, id) => ({
  type: UPDATE_ORDER_SERVICES,
  payload: { data, id },
});

export const updateOrderCustomer = (data, id) => ({
  type: UPDATE_ORDER_CUSTOMER,
  payload: { data, id },
});

export const updateOrderVehicle = (data, id) => ({
  type: UPDATE_ORDER_VEHICLE,
  payload: { data, id },
});

export const updateOrderContract = (data, id) => ({
  type: UPDATE_ORDER_CONTRACT,
  payload: { data, id },
});

export const updateOrderDocs = (data, id) => ({
  type: UPDATE_ORDER_DOCS,
  payload: { data, id },
});

export const submitOrder = id => ({
  type: SUBMIT_ORDER,
  payload: { id },
});

export const saveOrderOk = response => ({
  type: SAVE_ORDER_OK,
  payload: { response },
});

export const saveOrderErr = error => ({
  type: SAVE_ORDER_ERR,
  payload: { error },
});

export const doOrderFormCleanup = () => ({
  type: DO_ORDER_FORM_CLEANUP,
});

/********** VID **********/

export const getOrderVid = (id, signer) => ({
  type: GET_ORDER_VID,
  payload: { id, signer },
});

export const getOrderVidOk = (response, signer) => ({
  type: GET_ORDER_VID_OK,
  payload: { response, signer },
});

export const getOrderVidErr = (error, signer) => ({
  type: GET_ORDER_VID_ERR,
  payload: { error, signer },
});

export const doOrderVidCleanup = signer => ({
  type: DO_ORDER_VID_CLEANUP,
  payload: { signer },
});

/********** INK-SIGN DOCS **************/

export const getOrderInkSignDocs = id => ({
  type: GET_ORDER_INK_SIGN_DOCS,
  payload: { id },
});

export const getOrderInkSignDocsOk = response => ({
  type: GET_ORDER_INK_SIGN_DOCS_OK,
  payload: { response },
});

export const getOrderInkSignDocsErr = error => ({
  type: GET_ORDER_INK_SIGN_DOCS_ERR,
  payload: { error },
});

export const doOrderInkSignDocsCleanup = () => ({
  type: DO_ORDER_INK_SIGN_DOCS_CLEANUP,
});

/********** E-SIGN DOCS **************/

export const getOrderESignDocs = id => ({
  type: GET_ORDER_E_SIGN_DOCS,
  payload: { id },
});

export const getOrderESignDocsOk = response => ({
  type: GET_ORDER_E_SIGN_DOCS_OK,
  payload: { response },
});

export const getOrderESignDocsErr = error => ({
  type: GET_ORDER_E_SIGN_DOCS_ERR,
  payload: { error },
});

export const doOrderESignDocsCleanup = () => ({
  type: DO_ORDER_E_SIGN_DOCS_CLEANUP,
});

/********** SUPPORTING DOCS **************/

export const getOrderSupportingDocs = id => ({
  type: GET_ORDER_SUPPORTING_DOCS,
  payload: { id },
});

export const getOrderSupportingDocsOk = response => ({
  type: GET_ORDER_SUPPORTING_DOCS_OK,
  payload: { response },
});

export const getOrderSupportingDocsErr = error => ({
  type: GET_ORDER_SUPPORTING_DOCS_ERR,
  payload: { error },
});

export const doOrderSupportingDocsCleanup = () => ({
  type: DO_ORDER_SUPPORTING_DOCS_CLEANUP,
});

/********** PREVIEW DOCS **************/

export const getOrderPreviewDocs = id => ({
  type: GET_ORDER_PREVIEW_DOCS,
  payload: { id },
});

export const getOrderPreviewDocsOk = response => ({
  type: GET_ORDER_PREVIEW_DOCS_OK,
  payload: { response },
});

export const getOrderPreviewDocsErr = error => ({
  type: GET_ORDER_PREVIEW_DOCS_ERR,
  payload: { error },
});

export const doOrderPreviewDocsCleanup = () => ({
  type: DO_ORDER_PREVIEW_DOCS_CLEANUP,
});

/********** ACTIVITY **********/

export const getOrderActivity = (id, limit) => ({
  type: GET_ORDER_ACTIVITY,
  payload: { id, limit },
});

export const getOrderActivityOk = response => ({
  type: GET_ORDER_ACTIVITY_OK,
  payload: { response },
});

export const getOrderActivityErr = error => ({
  type: GET_ORDER_ACTIVITY_ERR,
  payload: { error },
});

export const doOrderActivityCleanup = () => ({
  type: DO_ORDER_ACTIVITY_CLEANUP,
});

/********** MESSAGES **********/

export const getOrderMessages = (orderId, channelId, params) => ({
  type: GET_ORDER_MESSAGES,
  payload: { orderId, channelId, params }
});

export const getOrderMessagesOk = (response, channelId) => ({
  type: GET_ORDER_MESSAGES_OK,
  payload: { response, channelId }
});

export const getOrderMessagesErr = error => ({
  type: GET_ORDER_MESSAGES_ERR,
  payload: { error }
});

export const doOrderMessagesCleanup = () => ({
  type: DO_ORDER_MESSAGES_CLEANUP,
});

export const getLastOrderMessage = orderId => ({
  type: GET_LAST_ORDER_MESSAGE,
  payload: { orderId },
});

export const getLastOrderMessageOk = response => ({
  type: GET_LAST_ORDER_MESSAGE_OK,
  payload: { response },
});

export const getLastOrderMessageErr = error => ({
  type: GET_LAST_ORDER_MESSAGE_ERR,
  payload: { error },
});

export const getChatMembers = (orderId, channelId) => ({
  type: GET_CHAT_MEMBERS,
  payload: { orderId, channelId }
});

export const getChatMembersOk = (response, channelId) => ({
  type: GET_CHAT_MEMBERS_OK,
  payload: { response, channelId }
});

export const getChatMembersErr = error => ({
  type: GET_CHAT_MEMBERS_ERR,
  payload: { error }
});

export const resetChannelUnreadMessages = channelId => ({
  type: RESET_CHANNEL_UNREAD_MESSAGES,
  payload: { channelId }
});