import React, { useEffect, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate, Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import { Container, Row } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb2";
import MetaTitle from "components/Shared/MetaTitle";
import { doOrderSingleCleanup, getFullOrder } from "store/actions";
import Error from "pages/Error";
import Preloader from "components/Shared/Preloader";
import { perms, useAccess } from "context/access";
import AccessDenied from "pages/Error/AccessDenied";
import Col from "components/Shared/Col";
import SectionCustomer from "../Partial/Section/Customer";
import SectionOrderInfo from "../Partial/Section/OrderInfo";
import SectionIdentityVerification from "../Partial/Section/IdentityVerification";
import SectionESignedDocs from "../Partial/Section/ESignedDocs";
import SectionInkSignedDocs from "../Partial/Section/InkSignedDocs";
// import SectionShippingInfo from "../Partial/Section/ShippingInfo";
import SectionFinanceDocs from "../Partial/Section/FinanceDocs";
import SectionActivity from "../Partial/Section/Activity";
import SectionMessages from "../Partial/Section/Messages";
import SectionSealInfo from "../Partial/Section/SealInfo";
import SealBadge from "../Partial/Seal/Badge";
import Order from "model/order";
import DealerGroup from "model/dealerGroup";
import { route, routes } from "helpers/routeHelper";
import { useSocketOn, useSubscribeToOrder } from "hooks/socket";
import socketEvent from "constants/socketEvent";
import { orderIsLocked, showError } from "helpers/utilHelper";
import { getOrderFees } from "helpers/backendHelper";
import SectionSupportingDocs from "../Partial/Section/SupportingDocs";
import SectionVehicle from "../Partial/Section/Vehicle";
import SectionContract from "../Partial/Section/Contract";
import SectionSchedulerInfo from "../Partial/Section/SchedulerInfo";
import SectionOrderActions from "../Partial/Section/OrderActions";
import SectionOrderSchedulerInfo from "../Partial/Section/OrderSchedulerInfo";
import SectionInactiveMessage from "../Partial/Section/InactiveStatus";
import SectionTrackingAndShipping from "../Partial/Section/TrackingAndShipping";
import SpecialInstructionsLabel from "../Partial/View/SpecialInstructionsLabel";
import SectionNotaryInfo from "../Partial/Section/NotaryInfo";
import SectionAccounting from "../Partial/Section/Accounting";

const ViewIndex = () => {

  let { id } = useParams();
  id = parseInt(id);

  // redux hook that dispatches actions
  const dispatch = useDispatch();
  // router hook that helps redirect
  const navigate = useNavigate();
  // hooks that check permissions
  const { iAmGranted, iAmNotGranted } = useAccess();

  /********** STATE **********/

  // get redux state from the store
  const { order, orderError, isLoadInProgress } = useSelector(state => state.Order.Single);

  // Calculate the time difference in hours
  const expiredTime = Math.floor((Date.now() - order?.updatedTs * 1000) / 3600000);

  const [fees, setFees] = useState();

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // make the initial remote call to get the order data
    refreshOrder();
    return () => {
      // state cleanup on component unmount
      dispatch(doOrderSingleCleanup());
    }
  }, [id]);

  // runs once on component mount
  useEffect(() => {
    // if the order is in status draft, redirect the user to the create order wizard
    if (order && order.status == Order.STATUS_DRAFT && iAmGranted(perms.create_orders)) {
      navigate(route(routes.new_order_customer, order.id));
    }
  }, [order]);

  // runs once on component mount
  useEffect(() => {
    getOrderFee();
  }, [id])

  /********** OTHER **********/

  const refreshOrder = useCallback(() => dispatch(getFullOrder(id)), [id]);

  const getOrderFee = () => {
    getOrderFees(id)
      .then((response) => {
        setFees(response.fees)
      })
      .catch(() => {
        showError("Unable to load fees data");
      })
  }
  /********** SOCKET **********/

  // start receiving updates about this particular document
  useSubscribeToOrder(order?.id);

  const onOrderChanged = useCallback(data => {
    // this socket client is shared by the entire app
    // and here we are listening for an event that might be triggered by multiple orders
    // therefore we need to check whether this update refers to the right order
    if (data.id == order.id) {
      refreshOrder();
    }
  }, [order?.id, refreshOrder]);

  const condition = useCallback(() => !!order?.id, [order?.id]);

  // listen for changes on order documents
  useSocketOn(socketEvent.orderChanged, onOrderChanged, condition);

  /********** OTHER **********/

  const isLocked = () => orderIsLocked(order.sealStatus);

  const isReadyForSealing = () => order.status == Order.STATUS_COMPLETED;

  const isQueuedForSealing = () => order.sealStatus == Order.SEAL_STATUS_QUEUED;

  const isSealed = () => order.sealStatus == Order.SEAL_STATUS_SEALED;

  const sealFailed = () => order.sealStatus == Order.SEAL_STATUS_FAILED;

  const isOrderCancelled = () => order?.status == Order.STATUS_CANCELLED;

  const canViewSeal = () => isReadyForSealing() || isQueuedForSealing() || sealFailed() || isSealed();

  const isShipping = () => order.docDeliveryOption == Order.DOC_DELIVERY_OPTION_SHIPPING;

  const isGroupSpecialInstructionsVisibile = order?.dealerGroupSpecialInstructionsAppliesTo?.some(item => item == DealerGroup.SPECIAL_INTSTRUCTIONS_APPLY_TO_DEALER);

  const canViewGroupSpecialInstructions = () => isGroupSpecialInstructionsVisibile && order?.dealerGroupSpecialInstructions;

  const isRemoteOrder = () => order.customerLocation === Order.CUSTOMER_LOCATION_REMOTE;

  return <React.Fragment>
    {iAmGranted(perms.view_orders) && <div className="page-content">
      {order && <React.Fragment>
        <MetaTitle>#{order.id} | Orders</MetaTitle>
        <Container fluid>
          <Row>
            <Col>
              <Breadcrumbs breadcrumbItems={breadcrumbs(order)} />
              {(canViewSeal() && canViewGroupSpecialInstructions()) && <SpecialInstructionsLabel instructions={order.dealerGroupSpecialInstructions} />}
            </Col>
            {(!canViewSeal() && canViewGroupSpecialInstructions()) && <Col xxl="4" className='text-end d-flex align-items-center justify-content-end mb-3 col-xxl-4'>
              {order.dealerGroupSpecialInstructions && <SpecialInstructionsLabel instructions={order.dealerGroupSpecialInstructions} />}
            </Col>}
            <SealBadge order={order} />
          </Row>
          {order.isInactive && order.status != Order.STATUS_CANCELLED && < Row >
            <Col>
              <SectionInactiveMessage time={expiredTime} />
            </Col>
          </Row>}
          {/* Add margin bottom if the SectionOrderSchedulerInfo doesn't exist */}
          <Row className={!(order.isInkSignRequired && order.isNotaryRequired) && 'mb-5'}>
            <Col>
              <SectionOrderActions order={order} id={id} refreshHandler={getOrderFee} />
            </Col>
          </Row>
          {order.isInkSignRequired && order.isNotaryRequired && <Row>
            <Col>
              <SectionOrderSchedulerInfo order={order} />
            </Col>
          </Row>}
          <div className={isOrderCancelled() && 'blurred'}>
            <Row>
              <Col xxl="6">
                <SectionCustomer id={id} order={order} refreshHandler={refreshOrder} isLoadBusy={isLoadInProgress} isLocked={isLocked()} />
              </Col>
              <Col xxl="6">
                {isRemoteOrder() && <Col xxl="12">
                  <SectionVehicle id={id} order={order} refreshHandler={refreshOrder} isLoadBusy={isLoadInProgress} isLocked={isLocked()} />
                </Col>}
                {isShipping() && <Col xxl="12">
                  <SectionTrackingAndShipping id={id} order={order} refreshHandler={refreshOrder} isLoadBusy={isLoadInProgress} isLocked={isLocked()} />
                </Col>}
              </Col>
              {order.isNotaryRequired && <Col xxl="6">
                <SectionContract id={id} order={order} refreshHandler={refreshOrder} isLoadBusy={isLoadInProgress} isLocked={isLocked()} />
              </Col>}
              <Col xxl="6">
                <SectionOrderInfo id={id} order={order} refreshHandler={refreshOrder} isLocked={isLocked()} />
              </Col>
            </Row>
            <Row>
              {order.isVidRequired && <Col xxl="3">
                <SectionIdentityVerification id={id} isLocked={isLocked()} order={order} />
              </Col>}

              {order.isEsignRequired && <Col xxl="3">
                <SectionESignedDocs id={id} isLocked={isLocked()} />
              </Col>}

              {order.isInkSignRequired && <Col xxl="3">
                <SectionInkSignedDocs id={id} isLocked={isLocked()} />
              </Col>}

              <Col xxl="3">
                <SectionActivity id={id} isLocked={isLocked()} />
              </Col>
              {isRemoteOrder() && <Col xxl="3">
                <SectionMessages id={id} isLocked={isLocked()} />
              </Col>}
              {order.isInkSignRequired && order.isNotaryRequired && <><Col xxl="3">
                <SectionSchedulerInfo order={order} />
              </Col>
                <Col xxl="3">
                  <SectionNotaryInfo order={order} />
                </Col></>}
              {canViewSeal() && <Col xxl="3">
                <SectionSealInfo order={order} isLocked={isLocked()} />
              </Col>}

              {(order.isEsignRequired || order.isInkSignRequired) && <>
                <Col xxl="3">
                  <SectionFinanceDocs id={id} isLocked={isLocked()} />
                </Col>
                <Col xxl="3">
                  <SectionSupportingDocs id={id} isLocked={isLocked()} />
                </Col>
              </>}
            </Row>
            <Row>
              <Col xxl="6">
                <SectionAccounting fees={fees?.filter(fee => !!fee.dealerStoreId)} order={order} />
              </Col>
            </Row>
            <Row>
              <Col className="text-end align-self-end">
                <div className="mb-4">
                  <Link to={route(routes.list_orders)} className="btn btn-primary btn-faded mt-3">Return to orders view</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </React.Fragment>}
      {/* Show this prealoder only on the first fetch */}
      {isLoadInProgress && !order && <Preloader className="inner" />}
      {orderError && <Error error={orderError} title404="Order not found" />}
    </div>
    }
    {iAmNotGranted(perms.view_orders) && <AccessDenied />}
  </React.Fragment >
}

const breadcrumbs = order => [{
  title: `${order.signers[0]?.fullName}`,
}, {
  title: 'Edit order',
}];

export default ViewIndex;
