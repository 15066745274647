import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { route, routes } from "helpers/routeHelper";

const useOrderServicesCheck = order => {

  const navigate = useNavigate();

  useEffect(() => {
    if (!!order && !order.servicesAreSelected) {
      // order services have not been selected
      // so redirect the user to the step where he can select them
      navigate(route(routes.new_order, null, { id: order.id }));
      return;
    }
  }, [order?.servicesAreSelected]);
}

export default useOrderServicesCheck;